<template>
  <section class="mt-32 mx-12">
        <div class="text-center">
            <!-- Features title -->
            <h3 class="text-4xl text-blue-900 font-bold">
                Features
            </h3>
            <!-- Features description -->
            <p class="mt-8 text-gray-500 text-lg">
                Know some of the features of communify and how blockchain <br class="hidden md:block"> technology can change how your suggestions or complaints are heard
            </p>
        </div>
        <!-- Features -->
        <div class="mt-24">
            <!-- One row -->
            <div class="block lg:flex">
                <!-- First feature -->
                <div class="md:flex items-center mx-auto lg:mr-10" style="background: url(../assets/img/features-1-bg.png) no-repeat right; background-size: contain;">
                    <div class="md:w-1/2">
                        <img class="mx-auto" src="@/assets/img/features-1.png" alt="">
                    </div>
                    <div class="md:w-1/2 text-center md:text-left ml-6 py-4">
                        <h1 class="text-3xl text-blue-900">
                            Keep your <br> privicy
                        </h1>
                        <p class="text-gray-500 mt-8">
                            You can access the complaints <br>
                            panel using your NEAR   <br>
                            wallet, remain anonymous
                        </p>
                        <a href="#" class="flex w-1/2 md:w-full mx-auto items-center text-blue-900 mt-4 lg:mt-16 hover:underline">
                            Learn more
                            <svg class="h-6 w-6 ml-6" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2852 15.001L21.2852 8.00098L15.2852 1.00098" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.2852 8.00098H1.28516" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
                <!-- Second feature -->
                <div class="md:flex items-center mx-auto mt-16 lg:mt-0 lg:ml-10" style="background: url(../assets/img/features-2-bg.png) no-repeat right; background-size: contain;">
                    <div class="md:w-1/2">
                        <img src="@/assets/img/features-2.png" alt="" class="mx-auto">
                    </div>
                    <div class="md:w-1/2 text-center md:text-left ml-6 py-4">
                        <h1 class="text-3xl text-blue-900">
                            Monitor the <br> results
                        </h1>
                        <p class="text-gray-500 mt-8">
                            Become a monitor of the <br>
                             problems of your community. <br>
                            Every problem you see in your <br>
                            community can be registered <br>
                            on the blockchain
                        </p>
                        <a href="#" class="flex w-1/2 md:w-full mx-auto items-center text-blue-900 mt-4 lg:mt-16 hover:underline">
                            Learn more
                            <svg class="h-6 w-6 ml-6" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2852 15.001L21.2852 8.00098L15.2852 1.00098" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.2852 8.00098H1.28516" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <!-- One row -->
            <div class="block lg:flex mt-16">
                <!-- Third feature -->
                <div class="md:flex items-center mx-auto mt-16 lg:mt-0 lg:mr-10" style="background: url(../assets/img/features-3-bg.png) no-repeat right; background-size: contain;">
                    <div class="md:w-1/2">
                        <img src="@/assets/img/features-3.png" alt="" class="mx-auto">
                    </div>
                    <div class="md:w-1/2 text-center md:text-left ml-6 py-4">
                        <h1 class="text-3xl text-blue-900">
                            Make them <br> listen to you
                        </h1>
                        <p class="text-gray-500 mt-8">
                            Make your city council work <br>
                            on the needs of your <br>
                            community based on your <br>
                            complaints and suggestions
                        </p>
                        <a href="#" class="flex w-1/2 md:w-full mx-auto items-center text-blue-900 mt-4 lg:mt-16 hover:underline">
                            Learn more
                            <svg class="h-6 w-6 ml-6" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2852 15.001L21.2852 8.00098L15.2852 1.00098" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.2852 8.00098H1.28516" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
                <!-- Fourth feature -->
                <div class="md:flex items-center mx-auto mt-16 lg:mt-0 lg:ml-10" style="background: url(../assets/img/features-4-bg.png) no-repeat right; background-size: contain;">
                    <div class="md:w-1/2">
                        <img src="@/assets/img/features-4.png" alt="features-4-bg" class="mx-auto">
                    </div>
                    <div class="md:w-1/2 text-center md:text-left ml-6 py-4">
                        <h1 class="text-3xl text-blue-900">
                            Your opinion is <br> unalterable
                        </h1>
                        <p class="text-gray-500 mt-8">
                            Blockchain makes that your <br>
                             opinion, suggestion or <br>
                             complaint never go away.
                        </p>
                        <a href="#" class="flex w-1/2 md:w-full mx-auto items-center text-blue-900 mt-4 lg:mt-16 hover:underline">
                            Learn more
                            <svg class="h-6 w-6 ml-6" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.2852 15.001L21.2852 8.00098L15.2852 1.00098" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M21.2852 8.00098H1.28516" stroke="#9C69E2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
}
</script>